import { Paper } from '@mui/material';
import { RefObject, useEffect, useState, useRef } from 'react';
import { GrExpand } from 'react-icons/gr';
import { RxTextAlignBottom, RxTextAlignTop } from 'react-icons/rx';
import { BsArrowsAngleContract } from 'react-icons/bs';
import iso6391 from 'iso-639-1';
import { SingleValue } from 'react-select';
import CustomLanguageSelect, {
  CustomOptionType,
  selectDefaultOption,
} from './custom-language-select/custom-language-select';
import { CiCirclePlus, CiEdit, CiFloppyDisk } from 'react-icons/ci';
import { MdClose } from 'react-icons/md';
import { MdOutlineClose } from 'react-icons/md';
import './transcript-component.scss';
import '@textabledev/langs-flags-list/lang-flags.css';
import { FiAlertTriangle } from 'react-icons/fi';
import {
  removePreviousBold,
  removePreviousSelected,
  updateHighlightsIndex,
  updatePlayerCurrentTime,
  formatDate,
} from '../utils';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import * as ruleViolationModalReducer from '../../../redux/reducers/ruleViolationModalReducer';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import * as reviewReducer from '../../../redux/reducers/reviewReducer';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { getLastReview, hasTheReviewBeenSent } from '../../../helpers/reviews-helper';
import { Review } from '../../../interface/review';
import AlertMessages from '../../../resources/messages/alerts.json';
import RuleViolationCard from '../issue-list-component/rule-violation-card';
import KeywordCard from '../issue-list-component/keyword-card';
import { IssueHightlight } from '../../../resources/submission/submission-types';
import submissionService from '../../../resources/submission/submission.service';
import { IOption } from '../../../components/ui/multi-select-dropdown';
import { MdAccessTime } from 'react-icons/md';
import SelectComponent from '../../../components/ui/select-component';
import IssuesModal from './issues-modal';
import LoaderComponent from '../../../components/ui/loader';
import { SubmissionIssueDTO } from '../../../interface/submission-issue-dto';
import { ISegment } from '../../../interface/segment';
import MultiStateSwitchComponent from '../../../components/ui/multi-state-switch';
import { MdOutlineVerticalAlignCenter, MdOutlineVerticalAlignTop } from 'react-icons/md';
import { FaPowerOff } from 'react-icons/fa6';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { MlOutputFeature } from '../../../interface/ml-output-feature';
import { SubmissionStatus } from '../../../enum/submission-status';
import ReviewSheetWarnModal from '../review-sheet-warn-modal';

interface TranscriptComponentProps {
  transcript: string;
  videoRef: RefObject<HTMLVideoElement>;
  updatePlayerState: (playerState: boolean) => void;
  updatePlaybackRate: (playbackRate: number) => void;
  onSyncChange: (mode: string) => void;
}

export default function TranscriptComponent({
  transcript,
  videoRef,
  updatePlayerState,
  updatePlaybackRate,
  onSyncChange,
}: TranscriptComponentProps) {
  const [tabValue, setTabValue] = useState(0);
  const [allOptions, setAllOptions] = useState<CustomOptionType[]>([]);
  const [boxPosition, setBoxPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [timeStart, setTimeStart] = useState<number>();
  const [timeEnd, setTimeEnd] = useState<number>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [showIssuesModal, setShowIssuesModal] = useState<boolean>(false);
  const [isSavingTranscript, setIsSavingTranscript] = useState<boolean>(false);
  const [originalSubmissionReviews, setOriginalSubmissionReviews] = useState<any>();
  const [originalCurrentRecord, setOriginalCurrentRecord] = useState<any>();
  const [isTranscriptTabLoading, setIsTranscriptTabLoading] = useState<boolean>(false);
  const [canChangeTranscriptVersion, setCanChangeTranscriptVersion] = useState<boolean>(true);
  const { hasChanges } = useAppSelector(state => state.review);
  const [showReviewSheetWarnModal, setShowReviewSheetWarnModal] = useState<boolean>(false);

  const lastSelectedOffsetRef = useRef<any>(null);
  const syncSwitchOptions = [
    { id: 'top', description: 'Align top', label: <MdOutlineVerticalAlignTop size={20} /> },
    { id: 'mid', description: 'Align center', label: <MdOutlineVerticalAlignCenter size={20} /> },
    { id: 'off', description: 'Off', label: <FaPowerOff size={15} /> },
  ];

  const dispatch = useAppDispatch();
  const {
    isTranscriptExtended,
    isSelectingContext,
    enableReview,
    submissionReviews,
    removedSubmissionReviews,
    blockedInteraction,
    submissionDetails,
    currentSubmissionIndex,
  } = useAppSelector(state => state.submission);
  const { currentHighlightsArray, currentContext, originalHighlightsArray, suggestedContext } = useAppSelector(
    state => state.ruleViolationModal,
  );
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const {
    showTranscriptAction,
    selectedIssueOccurrence,
    currentTab,
    filteredIssuesOccurrences,
    filteredIssuesRuleViolations,
    filteredIssuesKeywords,
    isDisplayingRejected,
    isEditingTranscript,
  } = useAppSelector(state => state.transcript);
  const selectedLanguage: CustomOptionType = useAppSelector(state => state.transcript.selectedLanguage);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const languageName = currentRecord?.languageName?.replaceAll(';', ', ');
  const lastReview = getLastReview(reviews);

  // Transcript versions
  const [selectedVersion, setSelectedVersion] = useState<IOption>(currentRecord.transcriptVersions[0]);

  useEffect(() => {
    const options = [{ value: '0', label: languageName, languageCode: iso6391.getCode(languageName).toString() }];

    if (
      languageName.toLowerCase() !== 'english' &&
      currentRecord.submissionStatus !== SubmissionStatus.TRANSCRIPTION_COMPLETED
    ) {
      options.push(selectDefaultOption);
    }

    setAllOptions(options);
    dispatch(transcriptReducer.setSelectedLanguage(options[0]));
  }, [languageName, currentSubmissionIndex]);

  // Event listener for text selection on mouseup.
  useEffect(() => {
    // Add event listener when component mounts.
    document.addEventListener('mouseup', handleTextSelection);

    // Clean up event listener when component unmounts.
    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
    };
  });

  useEffect(() => {
    let contentEl = document.getElementById('transcriptContent');
    if (contentEl) contentEl.innerHTML = transcript;
  }, [transcript]);

  // Event listener for transcript focusout.
  useEffect(() => {
    let transcriptContainer = document.querySelector('#transcriptContent');

    // Add event listener when component mounts.
    transcriptContainer!.addEventListener('focusout', handleTranscriptChanges);

    // Clean up event listener when component unmounts.
    return () => {
      transcriptContainer!.removeEventListener('focusout', handleTranscriptChanges);
    };
  }, [submissionReviews, removedSubmissionReviews]);

  // Event listener for changing transcript version
  useEffect(() => {
    loadTranscriptVersion();
  }, [selectedVersion]);

  useEffect(() => {
    videoRef.current?.addEventListener('play', () => {
      setIsPlaying(true);
    });
    videoRef.current?.addEventListener('pause', () => {
      setIsPlaying(false);
    });
  }, [videoRef.current]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEditingTranscript]);

  function handleBeforeUnload(event: BeforeUnloadEvent) {
    if (isEditingTranscript) {
      event.preventDefault();
      event.returnValue = '';
      return '';
    }
  }

  function handleTranscriptChanges() {
    const transcriptInnerHTML = document.querySelector('#transcriptContent')?.innerHTML.replaceAll('&nbsp;', ' ');
    const currentTranscriptContent = document.querySelector('#transcriptContent')?.textContent;

    const parser = new DOMParser();
    const doc = parser.parseFromString(transcriptInnerHTML!, 'text/html');

    const updatedSubmissionReviews: SubmissionIssueDTO[] = [...submissionReviews];
    let issuesToBeRemoved: SubmissionIssueDTO[] = [];

    submissionReviews.forEach((review: SubmissionIssueDTO) => {
      const issuesEl: HTMLSpanElement[] = Array.from(
        document.querySelectorAll(`span[data-issues*="${review.reviewIssue.id}"]`),
      );
      const highlightEl: HTMLSpanElement[] = Array.from(issuesEl).filter(el =>
        el.getAttribute('data-div')?.startsWith('ruleViolation'),
      );

      if (highlightEl.length === 0) {
        // Context fully removed, add it to the removedSubmissionReviews list.
        const newContext = issuesEl.map(el => el.textContent || '').join('');
        const clearedReview = {
          ...review,
          reviewIssue: {
            ...review.reviewIssue,
            issueContext: newContext,
            issueContent: '',
            issues: [],
          },
        };

        const submissionReviewIndexToBeUpdated = updatedSubmissionReviews.findIndex(
          sr => sr.reviewIssue.id === review.reviewIssue.id,
        );

        if (submissionReviewIndexToBeUpdated !== -1) {
          const removedSubmissionReview = updatedSubmissionReviews[submissionReviewIndexToBeUpdated];
          const issueAlreadyRemoved = removedSubmissionReviews.find(
            (issue: any) => issue.reviewIssue.id === removedSubmissionReview.reviewIssue.id,
          );
          if (issueAlreadyRemoved === undefined) {
            updatedSubmissionReviews[submissionReviewIndexToBeUpdated] = { ...clearedReview };

            if (currentRecord.languageName.toLowerCase() !== 'english' && review.reviewIssue.issueOrigin === 'ML') {
              return;
            }
            issuesToBeRemoved.push(removedSubmissionReview);
          }
        }
      } else if (!review.reviewIssue.issueContext) {
        if (!currentTranscriptContent?.toLowerCase().includes(review.reviewIssue.issueContent.toLowerCase())) {
          const newContent =
            issuesEl.length > 0 ? issuesEl.map(issue => issue.textContent).join('') : review.reviewIssue.issueContent;

          const updatedReview = {
            ...review,
            reviewIssue: {
              ...review.reviewIssue,
              issueContent: newContent,
            },
          };

          const submissionReviewIndexToBeUpdated = updatedSubmissionReviews.findIndex(
            sr => sr.reviewIssue.id === review.reviewIssue.id,
          );
          updatedSubmissionReviews[submissionReviewIndexToBeUpdated] = { ...updatedReview };
        }
      } else if (!currentTranscriptContent?.toLowerCase().includes(review.reviewIssue.issueContext.toLowerCase())) {
        // Context not found, update context and highlights.

        // Below algorithm explanation in steps:
        // 1. Get the transcript innerHTML.
        // 2. Find the context innerHTML.
        // 3. From the context innerHTML, get all the highlights elements.
        // 4. Loop through the highlights finding it's true position (outerHTML) relative to the context.
        // 4.1. Clear any html tags before the current highlight so the index is correct.

        // Get the context elements.
        const contextElements = doc.querySelectorAll(
          `span.issue-text-match.none[data-issues*="${review.reviewIssue.id}"]`,
        );

        // Combine them into a single HTML structure.
        let contextInnerHTML = Array.from(contextElements)
          .map(element => element.innerHTML!)
          .join('');

        // Join the textContent from each context element to retrieve the new context.
        let newContext = Array.from(contextElements)
          .map(element => element.textContent!)
          .join('');

        // Creates a doc reference from the new context.
        const innerDoc = parser.parseFromString(contextInnerHTML, 'text/html');

        // Find all highlights inside the new context.
        const issueElements = innerDoc.querySelectorAll(
          `span.issue-text-match.red[data-issues*="${review.reviewIssue.id}"]`,
        );

        // Extract all opening span tags from contextInnerHTML to be used for issue highlight replacements.
        const regexMatches = contextInnerHTML.match(/<span[^>]*>/g);

        let issueHightlights: IssueHightlight[] = [];

        issueElements.forEach((issue, index) => {
          // If not the first issue, remove the previous span tag and its closing tag.
          if (index > 0) {
            contextInnerHTML = contextInnerHTML.replace(regexMatches![index - 1], '').replace('</span>', '');
          }

          // Find the index position of the highlight content within contextInnerHTML (this retrieve the exact position because we are using the outerHTML that contains unique identifiers).
          const highlightIndex = contextInnerHTML.indexOf(issue.outerHTML);

          const issueHighlight: IssueHightlight = {
            issueContent: issue.textContent!,
            issueIndex: highlightIndex,
          };
          issueHightlights.push(issueHighlight);
        });

        // Create a new submissionIssue object with the updated issueContext and issues.
        const updatedReview = {
          ...review,
          reviewIssue: {
            ...review.reviewIssue,
            issueContext: newContext,
            issues: issueHightlights.reverse(), // This is IMPORTANT as the building of transcript is from last issue to the first one.
          },
        };

        const submissionReviewIndexToBeUpdated = updatedSubmissionReviews.findIndex(
          sr => sr.reviewIssue.id === review.reviewIssue.id,
        );
        updatedSubmissionReviews[submissionReviewIndexToBeUpdated] = { ...updatedReview };
      }
    });

    dispatch(submissionReducer.setSubmissionReviews(updatedSubmissionReviews));
    dispatch(submissionReducer.setRemovedSubmissionReviews([...removedSubmissionReviews, ...issuesToBeRemoved]));
    rebuildSegments();
  }

  // Queryselector the current segments to update them.
  function rebuildSegments() {
    let segments = getSegmentsFromTranscriptDOM();

    let updatedCurrentRecord = {
      ...currentRecord,
      segments: segments,
    };
    dispatch(submissionReducer.setCurrentRecord(updatedCurrentRecord));
  }

  function getSegmentsFromTranscriptDOM() {
    let segmentsEl: HTMLSpanElement[] = Array.from(document.querySelectorAll('.issue-text-match.none'));
    let segments: ISegment[] = [];
    segmentsEl.forEach((segmentEl: HTMLSpanElement) => {
      const segment: ISegment = {
        start: parseInt(segmentEl.getAttribute('data-start')!),
        end: parseInt(segmentEl.getAttribute('data-end')!),
        text: segmentEl.textContent || '',
        issuesIds: [],
      };
      segments.push(segment);
    });
    return segments;
  }

  function handleTextSelection(event: MouseEvent) {
    const selection = window.getSelection();

    // Review already submitted, don't allow the user to edit transcript or add rule violations.
    if (hasTheReviewBeenSent(lastReview) && !enableReview && selection && selection.toString()) {
      dispatch(submissionReducer.setRuleViolationsInfoMessage(AlertMessages.THE_REVIEW_HAS_ALREADY_BEEN_SENT));
      return;
    }

    const modal = document.querySelector('.modal');
    if (modal) return;

    const target = event.target as HTMLButtonElement;

    // Show the modal only if selecting text on the transcript.
    if (target.className || (target.className === '' && target.id === 'transcriptContent')) {
      // Check the type as it can also be a svg click (className that is not string).

      if (
        target.id === 'transcriptContent' ||
        (typeof target.className === 'string' && target.className.includes('issue-text-match'))
      ) {
        if (selection && selection.toString()) {
          const selectedContext = selection.toString();
          const selectedRange = selection.getRangeAt(0);
          const currentNode = selectedRange.startContainer.parentNode as Element;
          // const endNode = selectedRange.endContainer.parentNode as Element;
          const parentNode = selectedRange.commonAncestorContainer.parentNode as Element;
          let timeStart = 0;
          let timeEnd = 0;

          lastSelectedOffsetRef.current = {
            startOffset: selectedRange.startOffset,
            endOffset: selectedRange.endOffset,
          };

          var lastSelectedNode = selectedRange.endContainer.parentNode as Element;

          if (currentNode) {
            timeStart = parseInt(currentNode.getAttribute('data-start') || '0');
            timeEnd = parseInt(currentNode.getAttribute('data-end') || '0');
          } else if (parentNode) {
            timeStart = parseInt(parentNode.getAttribute('data-start') || '0');
            timeEnd = parseInt(parentNode.getAttribute('data-end') || '0');
          }

          if (lastSelectedNode.hasAttribute('data-end')) {
            timeEnd = parseInt(lastSelectedNode.getAttribute('data-end') || '0');
          } else if (lastSelectedNode.parentElement?.hasAttribute('data-end')) {
            timeEnd = parseInt(lastSelectedNode.parentElement?.getAttribute('data-end') || '0');
          }

          setTimeStart(timeStart);
          setTimeEnd(timeEnd);
          dispatch(ruleViolationModalReducer.setTimeStart(timeStart));
          dispatch(ruleViolationModalReducer.setTimeEnd(timeEnd));
          dispatch(ruleViolationModalReducer.setCurrentContext(selectedContext));

          // Open the Add Rule Violation modal directly
          if (isSelectingContext) {
            if (currentContext && currentContext !== '') {
              const updatedHighlightsArray = updateHighlightsIndex(
                currentContext || suggestedContext,
                selectedContext,
                currentHighlightsArray,
                originalHighlightsArray,
              );
              dispatch(ruleViolationModalReducer.setCurrentHighlightsArray(updatedHighlightsArray));
            }
            dispatch(ruleViolationModalReducer.setMode('issue'));
            dispatch(ruleViolationModalReducer.setShowModal(true));
            dispatch(submissionReducer.setIsSelectingContext(false));
          } else if (blockedInteraction) {
            dispatch(submissionReducer.setRuleViolationsInfoMessage(AlertMessages.NOT_IN_LAST_TRANSCRIPT_VERSION));
          } else {
            dispatch(transcriptReducer.setTranscriptSelectedContext(selectedContext));
            dispatch(transcriptReducer.setShowTranscriptAction(true));

            const boundingRect = (event.target as Element).getBoundingClientRect();

            let topOffset = isTranscriptExtended ? 20 : -160;
            let leftOffset = isTranscriptExtended ? 100 : 0;

            const topPosition = event.clientY + topOffset;
            const leftPosition = event.clientX - boundingRect.left + leftOffset;

            setBoxPosition({ top: topPosition, left: leftPosition });
          }
        } else {
          dispatch(ruleViolationModalReducer.setCurrentContext(''));
          dispatch(transcriptReducer.setShowTranscriptAction(false));
        }
      }
    }
  }

  function extendComponent() {
    const playerElement = document.querySelector('audio, video') as HTMLAudioElement | HTMLVideoElement;
    updatePlayerState(playerElement.paused);
    updatePlaybackRate(playerElement.playbackRate);
    dispatch(submissionReducer.setIsTranscriptExtended(!isTranscriptExtended));
  }

  function contextSelectCancel() {
    dispatch(ruleViolationModalReducer.setMode('issue'));
    dispatch(submissionReducer.setIsSelectingContext(false));
    dispatch(ruleViolationModalReducer.setShowModal(true));
  }

  // Handles the languague selection.
  const handleLanguageSelect = (newValue: SingleValue<CustomOptionType>) => {
    if (!newValue) return;

    clearInfoAlert();

    if (isEditingTranscript) {
      handleInfoMessage(AlertMessages.EDITING_TRANSCRIPT);
      return;
    }

    dispatch(transcriptReducer.setSelectedLanguage(newValue));
    setTabValue(Number(newValue.value));

    const isCurrentLanguage = newValue.label.toLowerCase() === currentRecord.languageName.toLowerCase();

    const findFeature = (type: string) =>
      currentRecord.features.find(
        (feature: any) =>
          feature.language &&
          feature.featureType === type &&
          feature.language.toLowerCase() ===
            (isCurrentLanguage ? currentRecord.languageName.toLowerCase() : newValue.label.toLowerCase()),
      );

    const transcriptFeature = findFeature('TRANSCRIPT');
    const segmentsFeature = findFeature('SEGMENTS');

    const transcriptContent = transcriptFeature ? transcriptFeature.content : currentRecord.transcript;
    const segmentsContent = segmentsFeature ? JSON.parse(segmentsFeature.content) : currentRecord.segments;

    let updatedCurrentRecord = { ...currentRecord };
    updatedCurrentRecord = { ...updatedCurrentRecord, transcript: transcriptContent, segments: segmentsContent };

    if (isCurrentLanguage) {
      setCanChangeTranscriptVersion(true);

      if (currentRecord.transcriptVersions.length > 1) {
        setSelectedVersion(currentRecord.transcriptVersions[1]);
      }

      setTimeout(() => {
        setSelectedVersion(currentRecord.transcriptVersions[0]);
      }, 0);

      dispatch(submissionReducer.setBlockedInteraction(false));
    } else {
      setCanChangeTranscriptVersion(false);
      dispatch(submissionReducer.setBlockedInteraction(true));
    }

    dispatch(submissionReducer.setCurrentRecord({ ...updatedCurrentRecord }));
  };

  /**
   * Open modal to add violation rules to the selected section.
   */
  function addRuleViolationSection() {
    clearInfoAlert();

    if (isEditingTranscript) {
      handleInfoMessage(AlertMessages.EDITING_TRANSCRIPT);
      return;
    }

    dispatch(transcriptReducer.setShowTranscriptAction(false));

    dispatch(ruleViolationModalReducer.setCurrentHighlightsArray([]));
    dispatch(ruleViolationModalReducer.setOriginalHighlightsArray([]));
    dispatch(ruleViolationModalReducer.setOriginalContext(''));
    dispatch(ruleViolationModalReducer.setReviewIssueId(null));
    dispatch(ruleViolationModalReducer.setReviewIssueViolationRules([]));
    dispatch(ruleViolationModalReducer.setTimeStart(timeStart));
    dispatch(ruleViolationModalReducer.setTimeEnd(timeEnd));

    dispatch(ruleViolationModalReducer.setMode('transcript'));
    dispatch(ruleViolationModalReducer.setShowModal(true));
  }

  function handleInfoMessage(message: string) {
    dispatch(submissionReducer.setRuleViolationsInfoMessage(message));
  }

  function clearInfoAlert() {
    dispatch(submissionReducer.setRuleViolationsInfoMessage(''));
  }

  /**
   * Transcript Actions click event to close it.
   */
  function closeTranscriptActions() {
    dispatch(transcriptReducer.setShowTranscriptAction(false));
  }

  /**
   * Up/down arrow function to navigate through occurrences.
   * @param isNext
   */
  function onChangeIssue(isNext: boolean) {
    if (currentTab === 'All') {
      handleNavigation(filteredIssuesOccurrences, isNext, isDisplayingRejected);
    } else if (currentTab === 'Rule Violation') {
      handleNavigation(filteredIssuesRuleViolations, isNext, isDisplayingRejected);
    } else if (currentTab === 'Keywords') {
      handleNavigation(filteredIssuesKeywords, isNext, isDisplayingRejected);
    }
  }

  /**
   * Handles the actual selection of previous/next occurrence.
   */
  function handleNavigation(issuesOccurrences: Array<any>, isNext: boolean, isDisplayingRejected: boolean) {
    let selectedIssueOccurrenceIndex = issuesOccurrences.findIndex(
      (issueOccurrence: any) => issueOccurrence.occurrence === selectedIssueOccurrence,
    );

    if (selectedIssueOccurrenceIndex !== -1) {
      dispatch(transcriptReducer.setByPassSelection(true));

      let nextOccurrence, previousOccurrence;

      // Function to find the next valid occurrence.
      const findNextOccurrence = (startIndex: number) => {
        for (let i = startIndex; i < issuesOccurrences.length; i++) {
          const occurrence = issuesOccurrences[i].occurrence;
          if (isDisplayingRejected || !occurrence.reviewIssue || occurrence.reviewIssue.userReviewStatus !== false) {
            return occurrence;
          }
        }
        return null;
      };

      // Function to find the previous valid occurrence.
      const findPreviousOccurrence = (startIndex: number) => {
        for (let i = startIndex; i >= 0; i--) {
          const occurrence = issuesOccurrences[i].occurrence;
          if (isDisplayingRejected || !occurrence.reviewIssue || occurrence.reviewIssue.userReviewStatus !== false) {
            return occurrence;
          }
        }
        return null;
      };

      // Determine the next and previous occurrences.
      if (selectedIssueOccurrenceIndex < issuesOccurrences.length - 1) {
        nextOccurrence = findNextOccurrence(selectedIssueOccurrenceIndex + 1);
      }

      if (selectedIssueOccurrenceIndex > 0) {
        previousOccurrence = findPreviousOccurrence(selectedIssueOccurrenceIndex - 1);
      }

      // Update the selected issue occurrence based on the direction (isNext).
      if (isNext) {
        if (nextOccurrence) {
          let startTime = nextOccurrence.startTime || nextOccurrence.reviewIssue?.timeStart;
          updatePlayerCurrentTime(startTime);
          dispatch(transcriptReducer.setSelectedIssueOccurrence(nextOccurrence));
          removePreviousBold();
          removePreviousSelected();
        }
      } else {
        if (previousOccurrence) {
          let startTime = previousOccurrence.startTime || previousOccurrence.reviewIssue?.timeStart;
          updatePlayerCurrentTime(startTime);
          dispatch(transcriptReducer.setSelectedIssueOccurrence(previousOccurrence));
          removePreviousBold();
          removePreviousSelected();
        }
      }
    }
  }

  /**
   * Enable transcript edition.
   */
  function editTranscript() {
    if (blockedInteraction) {
      if (canChangeTranscriptVersion) {
        dispatch(submissionReducer.setRuleViolationsInfoMessage(AlertMessages.NOT_IN_LAST_TRANSCRIPT_VERSION));
      }
      return;
    }

    // Save current state to restore if user cancels.
    setOriginalSubmissionReviews(submissionReviews);
    setOriginalCurrentRecord(currentRecord);

    dispatch(transcriptReducer.setIsEditingTranscript(true));
  }

  /**
   * Store edited transcript
   */
  function storeTranscript() {
    var newText = document.querySelector('#transcriptContent')?.textContent;
    var segments = getSegmentsFromTranscriptDOM();

    if (isSavingTranscript) {
      return;
    }

    if (currentRecord.transcript === newText) {
      return;
    }

    setIsTranscriptTabLoading(true);

    let updatedSubmissionReviews = [...submissionReviews];

    if (removedSubmissionReviews.length !== 0) {
      const removedIds = removedSubmissionReviews.map((review: SubmissionIssueDTO) => review.reviewIssue.id);
      updatedSubmissionReviews = updatedSubmissionReviews.filter(sr => !removedIds.includes(sr.reviewIssue.id));
    }
    dispatch(submissionReducer.setSubmissionReviews(updatedSubmissionReviews));

    setIsSavingTranscript(true);
    submissionService
      .createTranscriptVersion(currentRecord.submissionId, {
        segments: JSON.stringify(segments),
        transcript: newText!,
      })
      .promise.then(res => {
        let opt = { value: res[0].versionNumber, label: formatDate(res[0].createdAt) };

        submissionService
          .updateSubmissionIssues(currentRecord.submissionId, updatedSubmissionReviews)
          .promise.then((submissionIssues: SubmissionIssueDTO[]) => {
            dispatch(submissionReducer.pushTranscriptVersion(opt));
            setSelectedVersion(opt);
            dispatch(transcriptReducer.setIsEditingTranscript(false));
            setIsSavingTranscript(false);
            setShowIssuesModal(false);
            dispatch(submissionReducer.setRemovedSubmissionReviews([]));

            const updatedSubmissions: SubmissionDetailsDTO[] = [...submissionDetails?.submissions];
            const updatedSubmissionsReviews: SubmissionIssueDTO[][] = [...submissionDetails?.submissionsReviews];

            updatedSubmissions[currentSubmissionIndex] = {
              ...updatedSubmissions[currentSubmissionIndex], // Create a shallow copy of the object
              transcriptVersions: [
                opt, // Add the new option to the beginning
                ...updatedSubmissions[currentSubmissionIndex].transcriptVersions, // Spread the existing versions
              ],
            };

            updatedSubmissionsReviews[currentSubmissionIndex] = submissionIssues;

            dispatch(
              submissionReducer.setSubmissionDetails({
                ...submissionDetails,
                submissions: updatedSubmissions,
                submissionsReviews: updatedSubmissionsReviews,
              }),
            );
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            setIsSavingTranscript(false);
            setIsTranscriptTabLoading(false);
          });
      })
      .catch(error => {
        console.error(error);
        setIsSavingTranscript(false);
        setIsTranscriptTabLoading(false);
      });
  }

  function handleSaveButton() {
    if (hasChanges) {
      setShowReviewSheetWarnModal(true);
    } else {
      saveButton();
    }
  }

  function saveButton() {
    if (removedSubmissionReviews && removedSubmissionReviews.length > 0) {
      openIssuesModal();
    } else {
      storeTranscript();
    }
  }

  /**
   * Loads selectedVersion of transcript/segment from API and update
   * currentRecord to trigger building the transcript with hightlights
   */
  function loadTranscriptVersion() {
    if (!selectedVersion) {
      return;
    }

    submissionService
      .getTranscriptVersion(currentRecord.submissionId, parseInt(selectedVersion.value))
      .promise.then((features: Array<MlOutputFeature>) => {
        let updatedCurrentRecord = {
          ...currentRecord,
          segments: JSON.parse(
            features.filter(
              (f: MlOutputFeature) =>
                f.featureType === 'SEGMENTS' &&
                f.language?.toLocaleLowerCase() === currentRecord.languageName.toLocaleLowerCase(),
            )[0].content,
          ),
          transcript: features.filter(
            (f: MlOutputFeature) =>
              f.featureType === 'TRANSCRIPT' &&
              f.language?.toLocaleLowerCase() === currentRecord.languageName.toLocaleLowerCase(),
          )[0].content,
        };
        dispatch(submissionReducer.setCurrentRecord(updatedCurrentRecord));
      })
      .catch(err => {
        console.error('getTranscriptVersion error', err);
      });
  }

  /**
   * Version dropdown change handler.
   * Loads transcript version from API
   */
  function handleVersionChange(option: IOption) {
    const isLastVersion = parseInt(option.value) === currentRecord.transcriptVersions.length - 1;
    dispatch(submissionReducer.setBlockedInteraction(!isLastVersion));
    setSelectedVersion(option);
  }

  function openIssuesModal() {
    setShowIssuesModal(true);
  }

  function closeIssuesModal() {
    if (isSavingTranscript) {
      return;
    }
    setShowIssuesModal(false);
  }

  function cancelTranscriptEdition() {
    dispatch(submissionReducer.setSubmissionReviews(originalSubmissionReviews));
    dispatch(submissionReducer.setCurrentRecord(originalCurrentRecord));
    dispatch(submissionReducer.setRemovedSubmissionReviews([]));
    dispatch(transcriptReducer.setIsEditingTranscript(false));
  }

  function getUserSyncMode(): string | null {
    return localStorage.getItem('sync-mode');
  }

  return (
    <Paper className={'paperTranscript transcriptComponent ' + (isTranscriptExtended ? 'full' : '')} variant="outlined">
      <div className="transcriptMenu paper-title">
        <div className="paper-title">
          <span className="paperText">Transcript</span>
          {canChangeTranscriptVersion && !blockedInteraction && (
            <>
              <button
                onClick={isEditingTranscript ? () => handleSaveButton() : () => editTranscript()}
                className="btn btn-primary icon-btn inline">
                {isTranscriptTabLoading ? (
                  <LoaderComponent />
                ) : (
                  <>{isEditingTranscript ? <CiFloppyDisk /> : <CiEdit />}</>
                )}
              </button>
              {isEditingTranscript && !isTranscriptTabLoading && (
                <button onClick={cancelTranscriptEdition} className="btn btn-primary icon-btn inline">
                  <MdClose />
                </button>
              )}
            </>
          )}
        </div>
        <div className="functionals">
          {selectedLanguage.label &&
            currentRecord.languageName &&
            selectedLanguage.label.toLocaleLowerCase() === currentRecord.languageName.toLocaleLowerCase() && (
              <SelectComponent
                label={<MdAccessTime size={16} color="#AAAAAA" />}
                noBorders={true}
                noPadding={true}
                textColor="#5e677b"
                name="transcript-versions"
                onChange={handleVersionChange}
                isDisabled={isEditingTranscript || !canChangeTranscriptVersion}
                options={currentRecord.transcriptVersions}
                selectedOption={selectedVersion}
              />
            )}

          <div className="multi-state-switch">
            <MultiStateSwitchComponent
              options={syncSwitchOptions}
              name="sync-switch"
              label="Sync:"
              defaultOption={getUserSyncMode() || 'mid'}
              onChange={onSyncChange}
            />
          </div>

          <CustomLanguageSelect
            options={allOptions}
            isDisabled={allOptions.length === 1}
            handleChange={handleLanguageSelect}
          />
          <div className="optButtons">
            <button onClick={() => onChangeIssue(false)}>
              <RxTextAlignTop className="textTop" />
            </button>
            <button onClick={() => onChangeIssue(true)}>
              <RxTextAlignBottom className="texBottom" />
            </button>
            <button onClick={extendComponent}>
              {isTranscriptExtended ? <BsArrowsAngleContract className="expand" /> : <GrExpand className="expand" />}
            </button>
          </div>
        </div>
      </div>
      <div className="issue-occurrence-card-expanded">
        {isTranscriptExtended && (selectedIssueOccurrence.reviewIssue || selectedIssueOccurrence.keywordId) ? (
          selectedIssueOccurrence.reviewIssue && currentTab !== 'Keywords' ? (
            <RuleViolationCard
              occurrence={selectedIssueOccurrence}
              index={0}
              isDisplayingRejected={isDisplayingRejected}
            />
          ) : selectedIssueOccurrence.keywordId && currentTab !== 'Rule Violation' ? (
            <KeywordCard occurrence={selectedIssueOccurrence} />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
      <div className="transcriptParagraph dataParagraph">
        <div
          id="transcriptContent"
          className={isTranscriptExtended ? 'expanded' : ''}
          contentEditable={isEditingTranscript && canChangeTranscriptVersion}
          spellCheck="false"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevents creating a new paragraph
            }
          }}></div>
      </div>
      {showTranscriptAction && (
        <div className="transcriptActionsDiv" style={{ top: boxPosition.top, left: boxPosition.left }}>
          <div className="transcriptActionContainer" onClick={closeTranscriptActions}>
            <div>Transcript Actions</div>
            <MdOutlineClose />
          </div>
          <div className="divider"></div>
          <button className="itemContainer" onClick={addRuleViolationSection}>
            <div className="icon">
              <CiCirclePlus />
            </div>
            Add Rule Violation
          </button>
        </div>
      )}
      {isSelectingContext && isTranscriptExtended && (
        <div className={`contextToast ${isTranscriptExtended ? 'toast-extended' : ''}`}>
          <div>
            <FiAlertTriangle />
            <p>Select Context to continue</p>
          </div>
          <p className="cancel" onClick={contextSelectCancel}>
            Cancel
          </p>
        </div>
      )}
      <IssuesModal
        showModal={showIssuesModal}
        closeModal={closeIssuesModal}
        ruleViolations={removedSubmissionReviews}
        confirmButton={storeTranscript}
        isLoading={isSavingTranscript}
      />
      <ReviewSheetWarnModal
        showModal={showReviewSheetWarnModal}
        onCloseModal={() => setShowReviewSheetWarnModal(false)}
        action={saveButton}
      />
    </Paper>
  );
}
