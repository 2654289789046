import { AuthState, CustomUserClaims, OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { AxiosRequestConfig } from 'axios';
import environment from '../../config/env';
import { UserRole } from '../../interface/user';
import { AuthorizedService } from '../../service/authorized.service';
import { User } from './user-model';

// We may refactor and remove this
class AuthService extends AuthorizedService {
  private accessToken?: string | null;
  private idToken?: string | null;
  private config?: AxiosRequestConfig | null;
  private role?: UserRole | null;
  private nonce?: string | null;
  private user?: UserClaims<CustomUserClaims> | null;
  public userStored: boolean = false;

  get requestConfig(): AxiosRequestConfig | undefined | null {
    return this.config;
  }

  set oktaAuthState(oktaAuthState: AuthState | undefined | null) {
    if (oktaAuthState?.isAuthenticated) {
      this.accessToken = oktaAuthState.accessToken?.accessToken;
      this.idToken = oktaAuthState.idToken?.idToken;
      this.nonce = oktaAuthState.idToken?.claims.nonce;
      this.config = {
        headers: {
          Authorization: 'Bearer ' + this.accessToken,
        },
      };
    } else {
      this.accessToken = null;
      this.idToken = null;
      this.nonce = null;
      this.config = {};
      this.role = null;
      this.clearSession();
    }
  }

  public getLoggedUser(oktaAuth?: OktaAuth): Promise<UserClaims<CustomUserClaims> | undefined | null> {
    let user: UserClaims<CustomUserClaims> | undefined | null;

    if (this.user) {
      user = this.user;
    } else {
      user = this.getStoredSession();

      if (user) {
        this.user = user;
      } else if (oktaAuth) {
        return oktaAuth.token.getUserInfo().then(u => {
          this.user = u;
          return u;
        });
      }
    }

    return Promise.resolve(user);
  }

  public storeUser(nativeId?: string, email?: string, fullName?: string): Promise<void> {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: this.config?.headers?.Authorization as string,
        id_token: this.idToken as string,
        nonce: this.nonce as string,
      },
    };
    try {
      return super.post('user/storeUser', config, { params: { nativeId, email, fullName } });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public getUsers(usernameOrEmail: string): Promise<User[]> {
    return super.get<User[]>(`user/search`, { params: { usernameOrEmail } });
  }

  public clearSession(): void {
    this.clearLocalStorageExcept(['saved-filters', 'sync-mode']);
  }

  private getStoredSession(): UserClaims<CustomUserClaims> | undefined | null {
    const user = localStorage.getItem('user');

    return user ? JSON.parse(user) : null;
  }

  private storeSession(user?: UserClaims<CustomUserClaims> | null): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  private clearLocalStorageExcept(exceptions: string[]) {
    // Get all keys in localStorage
    Object.keys(localStorage).forEach(key => {
      // Remove the key from localStorage if it's not in the exceptions list
      if (!exceptions.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  }
}

const authService = new AuthService(environment.API_URL);
export default authService;
