import { useCallback, useEffect, useState } from 'react';
import { ExternalAction, ExternalData } from '../../@types/external-api';
import { Features } from '../../config/features';
import {
  makeExternalCallErrorData,
  makeExternalCallSuccessData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import useAuth from '../auth/auth-hook';
import PermissionsContext, { FeaturesCtxType } from './features-context';
import { ClaimPermissions, FeaturesMap, Permission } from './features-types';
import permissionsService from './permissions.service';

interface FeaturesProviderProps {
  children: React.ReactNode;
}

const FeaturesProvider: React.FC<FeaturesProviderProps> = ({ children }) => {
  //FIXME: change to isAuth service with okta
  const { isAuthenticated } = useAuth();
  const [updatePermissionsStatus, setUpdatePermissionsStatus] = useState<ExternalAction>({});
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [featuresMap, setFeaturesMap] = useState<ExternalData<FeaturesMap>>(makeExternalDataInitialData());
  const [claimsPermissions, setClaimsPermissions] = useState<ExternalData<ClaimPermissions[]>>(
    makeExternalDataInitialData(),
  );
  const [features, setFeatures] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchPermissions = useCallback(() => {
    if (isAuthenticated) {
      setLoading(true);

      permissionsService
        .getAuthorization()
        .promise.then(authorization => {
          const { permissions, features } = authorization;
          setPermissions(permissions);
          setFeatures(features);
        })
        .catch(err => {
          console.error(err);
          setPermissions([]);
          setFeatures([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPermissions([]);
      setFeatures([]);
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const updatePermissions: FeaturesCtxType['updatePermissions'] = useCallback(
    async permissions => {
      try {
        setUpdatePermissionsStatus(makeExternalDataInitialData);
        await permissionsService.update(permissions);
        fetchPermissions();
        setUpdatePermissionsStatus(makeExternalCallSuccessData);
      } catch (err: any) {
        setUpdatePermissionsStatus(makeExternalCallErrorData(err));
        // errorHandler(err);
      }
    },
    [fetchPermissions],
  );

  const hasFeature = useCallback(
    (feature: Features): boolean => {
      return !!features.find(userFeature => userFeature === feature);
    },
    [features],
  );

  const fetchClaimsPermissions: FeaturesCtxType['fetchClaimsPermissions'] = useCallback(async () => {
    try {
      setClaimsPermissions(makeExternalDataInitialData);
      // const claimsPermissions = await permissionsService.listClaimsPermissions();

      const claimsPermissions: ClaimPermissions[] = [
        {
          name: 'groups-ACDP-RT-SUPPORT',
          permissions: [Permission.BasicAccess],
        },
      ];
      setClaimsPermissions(makeExternalDataSuccessData(claimsPermissions));
    } catch (err: any) {
      setClaimsPermissions(makeExternalCallErrorData(err));
      // errorHandler(err);
    }
  }, []);

  const fetchFeaturesMap: FeaturesCtxType['fetchFeaturesMap'] = useCallback(async () => {
    try {
      setFeaturesMap(makeExternalDataInitialData);
      const featuresMap: FeaturesMap = {
        BasicAccess: ['DashboardScreen', 'SubmissionScreen'],
      };
      setFeaturesMap(makeExternalDataSuccessData(featuresMap));
    } catch (err: any) {
      setFeaturesMap(makeExternalCallErrorData(err));
      // errorHandler(err);
    }
  }, []);

  return (
    <PermissionsContext.Provider
      value={{
        loading,
        permissions,
        updatePermissionsStatus,
        featuresMap,
        claimsPermissions,
        updatePermissions,
        hasFeature,
        fetchClaimsPermissions,
        fetchFeaturesMap,
      }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default FeaturesProvider;
