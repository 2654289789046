export enum Permission {
  AdminAccess = 'AdminAccess',
  BasicAccess = 'BasicAccess',
}

export type FeaturesMap = Record<string, string[]>;

export interface Authorization {
  permissions: Permission[];
  features: string[];
}

export interface ClaimPermissions {
  name: string;
  permissions: Permission[];
}
