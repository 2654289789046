import './index.scss';

interface ErrorScreenProps {
  errorCode?: string;
  title?: string;
  message?: string;
}

export default function ErrorScreen({ errorCode, title, message }: ErrorScreenProps) {
  return (
    <div className="error-screen">
      <h1>{errorCode}</h1>
      <h2>{title}</h2>
      <h4>{message}</h4>
    </div>
  );
}
