import { useAppDispatch } from '../redux/hooks';
import * as optionsReducer from '../redux/reducers/optionsReducer';
import * as fetchHelper from '../helpers/fetch-helper';

export function useFetchOptionsData() {
  const dispatch = useAppDispatch();

  function fetchOptionsData({
    affiliates,
    workflowStatuses,
    submitters,
    assignees,
    approvedProviders,
  }: optionsReducer.OptionsState) {
    if (!affiliates || affiliates.length === 0) {
      fetchHelper.fetchAffiliates(dispatch);
    }
    if (!workflowStatuses || workflowStatuses.length === 0) {
      fetchHelper.fetchWorkflowStatus(dispatch);
    }
    if (!submitters || submitters.length === 0) {
      fetchHelper.fetchSubmitters(dispatch);
    }
    if (!assignees || assignees.length === 0) {
      fetchHelper.fetchAssignees(dispatch);
    }
    if (!approvedProviders || approvedProviders.length === 0) {
      fetchHelper.fetchApprovedProvider(dispatch);
    }
  }

  return { fetchOptionsData };
}
