import { Paper } from '@mui/material';
import { Button } from 'react-bootstrap';
import './index.scss';
import SelectComponent from '../../../components/ui/select-component';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useEffect, useMemo, useState } from 'react';
import { IOption } from '../../../components/ui/multi-select-dropdown';
import { ApprovedProvider } from '../../../interface/approved-provider';
import { Affiliate } from '../../../interface/affiliate';
import { capitalizeWords } from '../../../utils/string-utils';
import { useFetchOptionsData } from '../../../hooks/options-data-hook';
import { Assignee } from '../../../interface/assignee';
import { WorkflowStatus } from '../../../interface/workflow-status';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { SubmissionOriginProperty } from '../../../interface/submission-origin-property';
import selectOptions from './select-options.json';
import editSubmissionService from '../../../resources/edit-submission/edit-submission.service';
import { EditSubmissionDTO } from '../../../interface/edit-submission-dto';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import LoaderComponent from '../../../components/ui/loader';
import AlertComponent from '../../../components/ui/alert';
import moment from 'moment';

interface SubmissionDetailsFormState {
  [key: string]: {
    value: string;
    label: string;
  };
}

const SUBMITTER_TYPE_ASSOCIATION = 'Submitter Type / Association';
const BSM_LANGUAGE_SECONDARY = 'BSM Language Secondary';
const BSM_CREATE_DATE = 'BSM Create Date';
const PREVIOUSLY_SUBMITTED_BY_ANOTHER_AP = 'Previously Submitted by another AP?';
const BSM_NUMBER = 'BSM #';
const BSM_OBTAINED_FROM_ANOTHER_AP = 'BSM obtained from another AP?';
const VENDOR_OR_AP_NAME = 'Vendor / AP Name';
const AUTHORIZATION_TO_USE_BSM_FROM_AP = 'Do you have Authorization to use BSM from AP?';
const OBTAINED_FROM_AMWAY = 'Obtained from the Amway Corporation?';
const COPYRIGHTED_WORKS_OF_AMWAY_IN_BSM = 'Copyrighted Works of Amway in BSM?';
const AUTHORIZED_SIGNATURE = 'Authorized Signature';
const FIRST_LEVEL = '1st Level';
const SECOND_LEVEL = '2nd Level';
// Third level properties:
const GENERAL_BUSINESS = 'General Business';
const PLAN = 'Plan';
const PRODUCT = 'Product';
const PROSPECT = 'Prospect';

const defaultSubmissionDetailsFormState = {
  submitterType: { value: '', label: '' },
  bsmSku: { value: '', label: '' },
  approvedProvider: { value: '', label: '' },
  affiliate: { value: '', label: '' },
  assignedTo: { value: '', label: '' },
  bsmTitle: { value: '', label: '' },
  bsmFormat: { value: '', label: '' },
  speakers: { value: '', label: '' },
  primaryLanguage: { value: '', label: '' },
  secondaryLanguage: { value: '', label: '' },
  createDate: { value: '', label: '' },
  submittedAnotherAp: { value: '', label: '' },
  bsmNumber: { value: '', label: '' },
  obtainedAnotherAp: { value: '', label: '' },
  vendorOrApName: { value: '', label: '' },
  authorizationToUse: { value: '', label: '' },
  obtainedFromAmway: { value: '', label: '' },
  copyrighted: { value: '', label: '' },
  status: { value: '', label: '' },
  authorizedSignature: { value: '', label: '' },
  fstLevel: { value: '', label: '' },
  secondLevel: { value: '', label: '' },
  generalBusiness: { value: '', label: '' },
  plan: { value: '', label: '' },
  product: { value: '', label: '' },
  prospect: { value: '', label: '' },
};

export default function EditSubmissionTab() {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { currentSubmissionIndex } = useAppSelector(state => state.submission);
  const [submissionDetailsData, setSubmissionDetailsData] = useState<SubmissionDetailsFormState>(
    defaultSubmissionDetailsFormState,
  );
  const [currentSubmissionDetailsData, setCurrentSubmissionDetailsData] = useState<SubmissionDetailsFormState>(
    defaultSubmissionDetailsFormState,
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [infoMessage, setInfoMessage] = useState<string>('');
  const [isEditingSubmissionDetails, setIsEditingSubmissionDetails] = useState<boolean>(false);
  const { affiliates, workflowStatuses, submitters, assignees, approvedProviders } = useAppSelector(
    state => state.options,
  );
  const { fetchOptionsData } = useFetchOptionsData();
  const dispatch = useAppDispatch();

  // load all the dropdown options just once
  useEffect(() => {
    fetchOptionsData({ affiliates, workflowStatuses, submitters, assignees, approvedProviders });
  }, []);

  useEffect(() => {
    setSubmissionDetails();
  }, [approvedProviders, assignees, currentSubmissionIndex]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEditingSubmissionDetails]);

  useEffect(() => {
    const currentSubmissionDetailsDataStr = JSON.stringify(currentSubmissionDetailsData);
    const submissionDetailsDataStr = JSON.stringify(submissionDetailsData);
    const defaultSubmissionDetailsFormStateStr = JSON.stringify(defaultSubmissionDetailsFormState);
    const hasChanges =
      submissionDetailsDataStr !== defaultSubmissionDetailsFormStateStr &&
      submissionDetailsDataStr !== currentSubmissionDetailsDataStr;

    if (hasChanges) {
      setIsEditingSubmissionDetails(true);
    } else {
      setIsEditingSubmissionDetails(false);
    }
  }, [submissionDetailsData]);

  function handleBeforeUnload(event: BeforeUnloadEvent) {
    if (isEditingSubmissionDetails) {
      event.preventDefault();
      event.returnValue = '';
      return '';
    }
  }

  const getSubmissionOriginPropValue = (key: string) => getSubmissionOriginProperty(key)?.propertyValue || '';

  const getSubmissionOriginPropLabel = (key: string) => getSubmissionOriginProperty(key)?.propertyValue || '';

  function setSubmissionDetails() {
    const builtCurrentSubmissionDetailsData = {
      submitterType: {
        value: getSubmissionOriginPropValue(SUBMITTER_TYPE_ASSOCIATION),
        label: getSubmissionOriginPropLabel(SUBMITTER_TYPE_ASSOCIATION),
      },
      bsmSku: { value: currentRecord.sku, label: '' },
      approvedProvider: {
        value: currentRecord.approvedProviderName,
        label: currentRecord.approvedProviderName,
      },
      affiliate: {
        value: currentRecord.affiliateCd ? currentRecord.affiliateCd.toString() : '',
        label: currentRecord.affiliateCd ? capitalizeWords(currentRecord.affiliateName.toLowerCase()) : '',
      },
      assignedTo: { value: getAssigneeValue(currentRecord.assignedToName), label: currentRecord.assignedToName },
      bsmTitle: { value: currentRecord.englishTitle, label: '' },
      bsmFormat: { value: currentRecord.mediaType, label: currentRecord.mediaType },
      speakers: { value: currentRecord.speaker, label: '' },
      primaryLanguage: {
        value: currentRecord.languageName,
        label: currentRecord.languageName,
      },
      secondaryLanguage: {
        value: getSubmissionOriginPropValue(BSM_LANGUAGE_SECONDARY),
        label: getSubmissionOriginPropLabel(BSM_LANGUAGE_SECONDARY),
      },
      createDate: { value: getSubmissionOriginPropValue(BSM_CREATE_DATE), label: '' },
      submittedAnotherAp: {
        value: getSubmissionOriginPropValue(PREVIOUSLY_SUBMITTED_BY_ANOTHER_AP),
        label: getSubmissionOriginPropLabel(PREVIOUSLY_SUBMITTED_BY_ANOTHER_AP),
      },
      bsmNumber: { value: getSubmissionOriginPropValue(BSM_NUMBER), label: getSubmissionOriginPropLabel(BSM_NUMBER) },
      obtainedAnotherAp: {
        value: getSubmissionOriginPropValue(BSM_OBTAINED_FROM_ANOTHER_AP),
        label: getSubmissionOriginPropLabel(BSM_OBTAINED_FROM_ANOTHER_AP),
      },
      vendorOrApName: {
        value: getSubmissionOriginPropValue(VENDOR_OR_AP_NAME),
        label: getSubmissionOriginPropLabel(VENDOR_OR_AP_NAME),
      },
      authorizationToUse: {
        value: getSubmissionOriginPropValue(AUTHORIZATION_TO_USE_BSM_FROM_AP),
        label: getSubmissionOriginPropLabel(AUTHORIZATION_TO_USE_BSM_FROM_AP),
      },
      obtainedFromAmway: {
        value: getSubmissionOriginPropValue(OBTAINED_FROM_AMWAY),
        label: getSubmissionOriginPropLabel(OBTAINED_FROM_AMWAY),
      },
      copyrighted: {
        value: getSubmissionOriginPropValue(COPYRIGHTED_WORKS_OF_AMWAY_IN_BSM),
        label: getSubmissionOriginPropLabel(COPYRIGHTED_WORKS_OF_AMWAY_IN_BSM),
      },
      status: { value: getWorkflowStatusId(currentRecord.workflowStatus), label: currentRecord.workflowStatus },
      authorizedSignature: {
        value: getSubmissionOriginPropValue(AUTHORIZED_SIGNATURE),
        label: getSubmissionOriginPropLabel(AUTHORIZED_SIGNATURE),
      },
      fstLevel: { value: getSubmissionOriginPropValue(FIRST_LEVEL), label: '' },
      secondLevel: { value: getSubmissionOriginPropValue(SECOND_LEVEL), label: '' },
      generalBusiness: { value: getSubmissionOriginPropValue(GENERAL_BUSINESS), label: '' },
      plan: { value: getSubmissionOriginPropValue(PLAN), label: '' },
      product: { value: getSubmissionOriginPropValue(PRODUCT), label: '' },
      prospect: { value: getSubmissionOriginPropValue(PROSPECT), label: '' },
    };

    setCurrentSubmissionDetailsData(builtCurrentSubmissionDetailsData);
    setSubmissionDetailsData(builtCurrentSubmissionDetailsData);
  }

  function getAssigneeValue(assigneeName: string): string {
    return assignees.find((assignee: Assignee) => assignee.name === assigneeName)?.oktaId;
  }

  function getWorkflowStatusId(workflowStatusName: string): string {
    return workflowStatuses.find((workflowStatus: WorkflowStatus) => workflowStatus.status === workflowStatusName)?.id;
  }

  function getSubmissionOriginProperty(key: string): SubmissionOriginProperty | null {
    const submissionOriginProperty = currentRecord.submissionOriginProperties.find(
      (item: SubmissionOriginProperty) => item.propertyName === key,
    );

    return submissionOriginProperty || null;
  }

  const allAffiliatesOptions = useMemo<IOption[]>(() => {
    if (!affiliates) {
      return [] as IOption[];
    }

    const affiliatesOptions = affiliates.map((affiliate: Affiliate) => ({
      value: affiliate.affiliateCd.toString(),
      label: capitalizeWords(affiliate.affiliateName.toLowerCase()),
    })) as IOption[];

    return affiliatesOptions;
  }, [affiliates]);

  const allStatusOptions = useMemo<IOption[]>(() => {
    if (!workflowStatuses) {
      return [] as IOption[];
    }

    const statusOptions = workflowStatuses.map((workflowStatus: WorkflowStatus) => ({
      value: workflowStatus.id,
      label: workflowStatus.status,
    })) as IOption[];

    return statusOptions;
  }, [workflowStatuses]);

  const allUsersOptions = useMemo<IOption[]>(() => {
    if (!assignees) {
      return [] as IOption[];
    }

    const assignedTo = assignees.map((assignee: Assignee) => ({
      value: assignee.oktaId,
      label: assignee.name,
    })) as IOption[];

    return assignedTo;
  }, [assignees]);

  const allApprovedProviders = useMemo<IOption[]>(() => {
    if (!approvedProviders) {
      return [] as IOption[];
    }

    const approvedProvidersOptions = approvedProviders.map((approvedProvider: ApprovedProvider) => ({
      value: approvedProvider.acronym || approvedProvider.fullName,
      label: approvedProvider.fullName,
    })) as IOption[];

    return approvedProvidersOptions;
  }, [approvedProviders]);

  function handleSelectInputChange(name: string, option: IOption | IOption[]) {
    if (Array.isArray(option)) {
      const values = option.map(opt => opt.value).join(', ');
      const labels = option.map(opt => opt.label).join(', ');

      setSubmissionDetailsData(prevState => ({
        ...prevState,
        [name]: {
          value: values,
          label: labels,
        },
      }));
    } else {
      setSubmissionDetailsData(prevState => ({
        ...prevState,
        [name]: {
          value: option.value,
          label: option.label,
        },
      }));
    }
  }

  function handleTextInputChange(name: string, valueArg: string) {
    let value = valueArg;

    if (name === 'createDate' && moment(value).isAfter(moment())) {
      value = moment().format('YYYY-MM-DD');
    }

    setSubmissionDetailsData(prevState => ({
      ...prevState,
      [name]: {
        value,
        label: '',
      },
    }));
  }

  function handleCheckboxInputChange(name: string, checked: boolean) {
    setSubmissionDetailsData(prevState => ({
      ...prevState,
      [name]: {
        value: checked.toString(),
        label: '',
      },
    }));
  }

  function handleRadioInputChange(name: string, value: string) {
    setSubmissionDetailsData(prevState => ({
      ...prevState,
      [name]: {
        value: value,
        label: '',
      },
    }));
  }

  function confirmChanges() {
    setErrorMessage('');

    if (isUpdating) {
      return;
    }

    const message = checkInputs();

    if (message) {
      setErrorMessage(message);
      return;
    }

    const editSubmissionDTO = getEditSubmissionDTO();

    if (Object.values(editSubmissionDTO).every(value => value === null || value === undefined)) {
      setInfoMessage('No changes have been made. Please update at least one field before submitting.');
      return;
    }

    setIsUpdating(true);

    editSubmissionService
      .updateSubmissionDetails(currentRecord.submissionId, editSubmissionDTO)
      .promise.then((submissionDetails: EditSubmissionDTO) => {
        updateSubmissionDetails(submissionDetails);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }

  function checkInputs(): string {
    const getCannotBeEmptyMessage = (field: string) =>
      `The field "${field}" cannot be empty. Please enter a value to proceed`;
    const getFieldRequiredMessage = (field: string) =>
      `The field "${field}" is required. Please fill it in before proceeding.`;

    if (
      submissionDetailsData.submittedAnotherAp.value === 'Yes' &&
      submissionDetailsData.bsmNumber.value.trim().length === 0
    ) {
      return getFieldRequiredMessage(BSM_NUMBER);
    }
    if (
      submissionDetailsData.obtainedAnotherAp.value === 'Yes' &&
      submissionDetailsData.vendorOrApName.value.length === 0
    ) {
      return getFieldRequiredMessage(VENDOR_OR_AP_NAME);
    }
    if (
      submissionDetailsData.obtainedAnotherAp.value === 'Yes' &&
      submissionDetailsData.authorizationToUse.value.length === 0
    ) {
      return getFieldRequiredMessage(AUTHORIZATION_TO_USE_BSM_FROM_AP);
    }
    if (currentRecord.sku && currentRecord.sku.length > 0 && submissionDetailsData.bsmSku.value.trim().length === 0) {
      return getCannotBeEmptyMessage('BSM SKU');
    }
    if (
      currentRecord.englishTitle &&
      currentRecord.englishTitle.length > 0 &&
      submissionDetailsData.bsmTitle.value.trim().length === 0
    ) {
      return getCannotBeEmptyMessage('BSM Title');
    }
    if (
      currentRecord.speaker &&
      currentRecord.speaker.length > 0 &&
      submissionDetailsData.speakers.value.trim().length === 0
    ) {
      return getCannotBeEmptyMessage('Identify Speakers / Created By');
    }
    if (submissionDetailsData.createDate.value.length === 0) {
      return getCannotBeEmptyMessage(BSM_CREATE_DATE);
    }
    if (
      submissionDetailsData.generalBusiness.value === 'false' &&
      submissionDetailsData.plan.value === 'false' &&
      submissionDetailsData.product.value === 'false' &&
      submissionDetailsData.prospect.value === 'false'
    ) {
      return getCannotBeEmptyMessage('3rd Level');
    }

    return '';
  }

  function getEditSubmissionDTO(): EditSubmissionDTO {
    return {
      submitterType:
        getSubmissionOriginPropValue(SUBMITTER_TYPE_ASSOCIATION) === submissionDetailsData.submitterType.value
          ? undefined
          : submissionDetailsData.submitterType.value,
      bsmSku: currentRecord.sku === submissionDetailsData.bsmSku.value ? undefined : submissionDetailsData.bsmSku.value,
      approvedProvider:
        currentRecord.approvedProviderName === submissionDetailsData.approvedProvider.value
          ? undefined
          : submissionDetailsData.approvedProvider.value,
      affiliate:
        currentRecord.affiliateCd === Number.parseInt(submissionDetailsData.affiliate.value, 10)
          ? undefined
          : Number.parseInt(submissionDetailsData.affiliate.value, 10),
      assignedTo:
        getAssigneeValue(currentRecord.assignedToName) === submissionDetailsData.assignedTo.value
          ? undefined
          : submissionDetailsData.assignedTo.value,
      bsmTitle:
        currentRecord.englishTitle === submissionDetailsData.bsmTitle.value
          ? undefined
          : submissionDetailsData.bsmTitle.value,
      bsmFormat:
        currentRecord.mediaType === submissionDetailsData.bsmFormat.value
          ? undefined
          : submissionDetailsData.bsmFormat.value,
      speakers:
        currentRecord.speaker === submissionDetailsData.speakers.value
          ? undefined
          : submissionDetailsData.speakers.value,
      primaryLanguage:
        currentRecord.languageName === submissionDetailsData.primaryLanguage.value
          ? undefined
          : submissionDetailsData.primaryLanguage.value,
      secondaryLanguage:
        getSubmissionOriginPropValue(BSM_LANGUAGE_SECONDARY) === submissionDetailsData.secondaryLanguage.value
          ? undefined
          : submissionDetailsData.secondaryLanguage.value,
      createDate:
        getSubmissionOriginPropValue(BSM_CREATE_DATE) === submissionDetailsData.createDate.value ||
        submissionDetailsData.createDate.value.length === 0
          ? undefined
          : submissionDetailsData.createDate.value,
      submittedAnotherAp:
        getSubmissionOriginPropValue(PREVIOUSLY_SUBMITTED_BY_ANOTHER_AP) ===
        submissionDetailsData.submittedAnotherAp.value
          ? undefined
          : submissionDetailsData.submittedAnotherAp.value,
      bsmNumber:
        getSubmissionOriginPropValue(BSM_NUMBER) === submissionDetailsData.bsmNumber.value ||
        submissionDetailsData.submittedAnotherAp.value !== 'Yes'
          ? undefined
          : submissionDetailsData.bsmNumber.value,
      obtainedAnotherAp:
        getSubmissionOriginPropValue(BSM_OBTAINED_FROM_ANOTHER_AP) === submissionDetailsData.obtainedAnotherAp.value
          ? undefined
          : submissionDetailsData.obtainedAnotherAp.value,
      vendorOrApName:
        getSubmissionOriginPropValue(VENDOR_OR_AP_NAME) === submissionDetailsData.vendorOrApName.value ||
        submissionDetailsData.obtainedAnotherAp.value !== 'Yes'
          ? undefined
          : submissionDetailsData.vendorOrApName.value,
      authorizationToUse:
        getSubmissionOriginPropValue(AUTHORIZATION_TO_USE_BSM_FROM_AP) ===
          submissionDetailsData.authorizationToUse.value || submissionDetailsData.obtainedAnotherAp.value !== 'Yes'
          ? undefined
          : submissionDetailsData.authorizationToUse.value,
      obtainedFromAmway:
        getSubmissionOriginPropValue(OBTAINED_FROM_AMWAY) === submissionDetailsData.obtainedFromAmway.value
          ? undefined
          : submissionDetailsData.obtainedFromAmway.value,
      copyrighted:
        getSubmissionOriginPropValue(COPYRIGHTED_WORKS_OF_AMWAY_IN_BSM) === submissionDetailsData.copyrighted.value
          ? undefined
          : submissionDetailsData.copyrighted.value,
      status:
        currentRecord.workflowStatus === submissionDetailsData.status.label
          ? undefined
          : Number.parseInt(submissionDetailsData.status.value, 10),
      authorizedSignature:
        getSubmissionOriginPropValue(AUTHORIZED_SIGNATURE) === submissionDetailsData.authorizedSignature.value
          ? undefined
          : submissionDetailsData.authorizedSignature.value,
      fstLevel:
        getSubmissionOriginPropValue(FIRST_LEVEL) === submissionDetailsData.fstLevel.value
          ? undefined
          : submissionDetailsData.fstLevel.value,
      secondLevel:
        getSubmissionOriginPropValue(SECOND_LEVEL) === submissionDetailsData.secondLevel.value ||
        submissionDetailsData.fstLevel.value !== 'ibo'
          ? undefined
          : submissionDetailsData.secondLevel.value,
      generalBusiness:
        getSubmissionOriginPropValue(GENERAL_BUSINESS) === submissionDetailsData.generalBusiness.value
          ? undefined
          : submissionDetailsData.generalBusiness.value,
      plan:
        getSubmissionOriginPropValue(PLAN) === submissionDetailsData.plan.value
          ? undefined
          : submissionDetailsData.plan.value,
      product:
        getSubmissionOriginPropValue(PRODUCT) === submissionDetailsData.product.value
          ? undefined
          : submissionDetailsData.product.value,
      prospect:
        getSubmissionOriginPropValue(PROSPECT) === submissionDetailsData.prospect.value
          ? undefined
          : submissionDetailsData.prospect.value,
    };
  }

  function updateSubmissionDetails(submissionDetails: EditSubmissionDTO) {
    const getAffiliateName = (affiliateCd: number): string => {
      return affiliates.find((affiliate: Affiliate) => affiliate.affiliateCd === affiliateCd)?.affiliateName;
    };

    const getAssignedToName = (assigneeOktaId: string): string => {
      return assignees.find((assignee: Assignee) => assignee.oktaId === assigneeOktaId)?.name;
    };

    const getWorkflowStatusName = (workflowStatusId: number): string => {
      return workflowStatuses.find((workflowStatus: WorkflowStatus) => workflowStatus.id === workflowStatusId)?.status;
    };

    // Create a new object with updated properties
    const updatedFields: Partial<SubmissionDetailsDTO> = {};

    if (submissionDetails.approvedProvider !== undefined && submissionDetails.approvedProvider !== null) {
      updatedFields.approvedProviderName = submissionDetails.approvedProvider;
    }

    if (submissionDetails.affiliate !== undefined && submissionDetails.affiliate !== null) {
      updatedFields.affiliateCd = submissionDetails.affiliate;
      updatedFields.affiliateName = getAffiliateName(submissionDetails.affiliate);
    }

    if (submissionDetails.assignedTo !== undefined && submissionDetails.assignedTo !== null) {
      updatedFields.assignedToName = getAssignedToName(submissionDetails.assignedTo);
    }

    if (submissionDetails.bsmTitle !== undefined && submissionDetails.bsmTitle !== null) {
      updatedFields.englishTitle = submissionDetails.bsmTitle;
    }

    if (submissionDetails.bsmFormat !== undefined && submissionDetails.bsmFormat !== null) {
      updatedFields.mediaType = submissionDetails.bsmFormat;
    }

    if (submissionDetails.speakers !== undefined && submissionDetails.speakers !== null) {
      updatedFields.speaker = submissionDetails.speakers;
    }

    if (submissionDetails.primaryLanguage !== undefined && submissionDetails.primaryLanguage !== null) {
      updatedFields.languageName = submissionDetails.primaryLanguage;
    }

    if (submissionDetails.status !== undefined && submissionDetails.status !== null) {
      updatedFields.workflowStatus = getWorkflowStatusName(submissionDetails.status);
    }

    if (submissionDetails.bsmSku !== undefined && submissionDetails.bsmSku !== null) {
      updatedFields.sku = submissionDetails.bsmSku;
    }

    if (submissionDetails) {
      updatedFields.submissionOriginProperties = mapSubmissionOriginProperties(submissionDetails);
    }

    // Only update the current record with the fields that were changed
    const updatedCurrentRecord = {
      ...currentRecord,
      ...updatedFields,
    } as SubmissionDetailsDTO;

    dispatch(submissionReducer.setCurrentRecord(updatedCurrentRecord));
  }

  function mapSubmissionOriginProperties(submissionDetails: EditSubmissionDTO): SubmissionOriginProperty[] {
    const existingProperties = [...currentRecord.submissionOriginProperties];

    const submissionOriginPropertiesMap = {
      submitterType: SUBMITTER_TYPE_ASSOCIATION,
      secondaryLanguage: BSM_LANGUAGE_SECONDARY,
      createDate: BSM_CREATE_DATE,
      submittedAnotherAp: PREVIOUSLY_SUBMITTED_BY_ANOTHER_AP,
      bsmNumber: BSM_NUMBER,
      obtainedAnotherAp: BSM_OBTAINED_FROM_ANOTHER_AP,
      vendorOrApName: VENDOR_OR_AP_NAME,
      authorizationToUse: AUTHORIZATION_TO_USE_BSM_FROM_AP,
      obtainedFromAmway: OBTAINED_FROM_AMWAY,
      copyrighted: COPYRIGHTED_WORKS_OF_AMWAY_IN_BSM,
      authorizedSignature: AUTHORIZED_SIGNATURE,
      fstLevel: FIRST_LEVEL,
      secondLevel: SECOND_LEVEL,
      generalBusiness: GENERAL_BUSINESS,
      plan: PLAN,
      product: PRODUCT,
      prospect: PROSPECT,
    };

    // Create a copy to track the updated or new properties
    const updatedProperties = new Map<string, SubmissionOriginProperty>();

    // Update existing properties or add new ones
    Object.keys(submissionOriginPropertiesMap).forEach(key => {
      const mappedName = submissionOriginPropertiesMap[key as keyof typeof submissionOriginPropertiesMap];
      const value = submissionDetails[key as keyof EditSubmissionDTO];

      // Skip null or undefined values
      if (value == null) {
        return;
      }

      // Check if property already exists
      const existingProperty = existingProperties.find(p => p.propertyName === mappedName);

      if (existingProperty) {
        // Update the property value
        updatedProperties.set(mappedName, {
          ...existingProperty,
          propertyValue: String(value),
        });
      } else {
        // Add new property
        updatedProperties.set(mappedName, {
          id: -1,
          submissionId: currentRecord.submissionId,
          propertyName: mappedName,
          propertyValue: String(value),
          createdAt: new Date(),
        });
      }
    });

    // Merge the existing properties with the updated ones
    const finalProperties = existingProperties.map(prop => updatedProperties.get(prop.propertyName) || prop);

    // Add any new properties not in the original array
    updatedProperties.forEach((prop, key) => {
      if (!existingProperties.find(existingProp => existingProp.propertyName === key)) {
        finalProperties.push(prop);
      }
    });

    return finalProperties;
  }

  return (
    <div>
      {errorMessage && (
        <AlertComponent text={errorMessage} type="danger" onClick={() => setErrorMessage('')} buttonText="X" />
      )}
      {infoMessage && (
        <AlertComponent text={infoMessage} type="info" onClick={() => setInfoMessage('')} buttonText="X" />
      )}
      <Paper className="edit-submission-tab-container contentCard" variant="outlined">
        <div className="paper-title">
          <span className="paperText">Submission properties</span>
        </div>
        <div className="edit-submission-tab-body">
          <div className="edit-submission-inputs">
            <div className="edit-submission-tab-content-col">
              <SelectComponent
                label="Submitter type / Association: "
                name="submitter-type"
                onChange={(option: IOption) => handleSelectInputChange('submitterType', option)}
                isDisabled={false}
                options={selectOptions['submitter-types']}
                selectedOption={submissionDetailsData.submitterType}
              />
              <div className="edit-submission-input-container">
                <label htmlFor="bsm-sku" className="edit-submission-input-label">
                  BSM SKU:{' '}
                </label>
                <input
                  className="cc-input"
                  type="text"
                  id="bsm-sku"
                  onChange={e => handleTextInputChange('bsmSku', e.target.value)}
                  value={submissionDetailsData.bsmSku?.value || ''}
                />
              </div>
              <SelectComponent
                label="Approved Provider Name: "
                name="ap-name"
                onChange={(option: IOption) => handleSelectInputChange('approvedProvider', option)}
                isDisabled={false}
                options={allApprovedProviders}
                selectedOption={submissionDetailsData.approvedProvider}
              />
              <SelectComponent
                label="Affiliate: "
                name="affiliate"
                onChange={(option: IOption) => handleSelectInputChange('affiliate', option)}
                isDisabled={false}
                options={allAffiliatesOptions}
                selectedOption={submissionDetailsData.affiliate}
              />
              <SelectComponent
                label="Assigned to: "
                name="assigned-to"
                onChange={(option: IOption) => handleSelectInputChange('assignedTo', option)}
                isDisabled={false}
                options={allUsersOptions}
                selectedOption={submissionDetailsData.assignedTo}
              />
              <div className="edit-submission-input-container">
                <label htmlFor="bsm-title" className="edit-submission-input-label">
                  BSM Title:{' '}
                </label>
                <input
                  className="cc-input"
                  type="text"
                  id="bsm-title"
                  onChange={e => handleTextInputChange('bsmTitle', e.target.value)}
                  value={submissionDetailsData.bsmTitle?.value || ''}
                />
              </div>
              <SelectComponent
                label="BSM Format / BSM Type: "
                name="bsm-format"
                onChange={(option: IOption) => handleSelectInputChange('bsmFormat', option)}
                isDisabled={true}
                options={selectOptions['file-types']}
                selectedOption={submissionDetailsData.bsmFormat}
              />
              <div className="edit-submission-input-container">
                <label htmlFor="speakers" className="edit-submission-input-label">
                  Identify Speakers / Created By:{' '}
                </label>
                <input
                  className="cc-input"
                  type="text"
                  id="speakers"
                  onChange={e => handleTextInputChange('speakers', e.target.value)}
                  value={submissionDetailsData.speakers?.value || ''}
                />
              </div>
              <SelectComponent
                label="BSM Language Primary: "
                name="primary-language"
                onChange={(option: IOption) => handleSelectInputChange('primaryLanguage', option)}
                isDisabled={true}
                options={selectOptions['primary-languages']}
                selectedOption={submissionDetailsData.primaryLanguage}
              />
              <SelectComponent
                label="BSM Language Secondary: "
                name="secondary-language"
                onChange={(option: IOption[]) => handleSelectInputChange('secondaryLanguage', option)}
                isDisabled={false}
                options={selectOptions['secondary-languages']}
                selectedOption={
                  submissionDetailsData.secondaryLanguage.value
                    ? submissionDetailsData.secondaryLanguage.value.split(', ').map((val, index) => ({
                        value: val,
                        label: submissionDetailsData.secondaryLanguage.label.split(', ')[index],
                      }))
                    : []
                }
                multiple={true}
              />
              <div className="edit-submission-input-container">
                <label htmlFor="create-date" className="edit-submission-input-label">
                  BSM Create Date:{' '}
                </label>
                <input
                  className="cc-input"
                  type="date"
                  id="create-date"
                  max={moment().format('YYYY-MM-DD')}
                  onChange={e => handleTextInputChange('createDate', e.target.value)}
                  value={submissionDetailsData.createDate?.value || ''}
                />
              </div>
            </div>
            <div className="edit-submission-tab-content-col">
              <SelectComponent
                label="Status: "
                name="status"
                onChange={(option: IOption) => handleSelectInputChange('status', option)}
                isDisabled={false}
                options={allStatusOptions}
                selectedOption={submissionDetailsData.status}
              />
              <SelectComponent
                label="Previously Submitted by another AP? "
                name="submitted-another-ap"
                onChange={(option: IOption) => handleSelectInputChange('submittedAnotherAp', option)}
                isDisabled={false}
                options={selectOptions['yes-no-options']}
                selectedOption={submissionDetailsData.submittedAnotherAp}
              />
              {submissionDetailsData.submittedAnotherAp.value === 'Yes' && (
                <div className="edit-submission-input-container">
                  <label htmlFor="bsm-number" className="edit-submission-input-label">
                    BSM #:{' '}
                  </label>
                  <input
                    className="cc-input"
                    type="text"
                    id="bsm-number"
                    onChange={e => handleTextInputChange('bsmNumber', e.target.value)}
                    value={submissionDetailsData.bsmNumber?.value || ''}
                  />
                </div>
              )}
              <SelectComponent
                label="BSM obtained from another AP? "
                name="obtained-another-ap"
                onChange={(option: IOption) => handleSelectInputChange('obtainedAnotherAp', option)}
                isDisabled={false}
                options={selectOptions['yes-no-options']}
                selectedOption={submissionDetailsData.obtainedAnotherAp}
              />
              {submissionDetailsData.obtainedAnotherAp.value === 'Yes' && (
                <SelectComponent
                  label="Vendor / AP Name: "
                  name="vendor-or-ap-name"
                  onChange={(option: IOption) => handleSelectInputChange('vendorOrApName', option)}
                  isDisabled={false}
                  options={allApprovedProviders}
                  selectedOption={submissionDetailsData.vendorOrApName}
                />
              )}
              {submissionDetailsData.obtainedAnotherAp.value === 'Yes' && (
                <SelectComponent
                  label="Do you have Authorization to use BSM from AP? "
                  name="authorization-to-use"
                  onChange={(option: IOption) => handleSelectInputChange('authorizationToUse', option)}
                  isDisabled={false}
                  options={selectOptions['yes-no-options']}
                  selectedOption={submissionDetailsData.authorizationToUse}
                />
              )}
              <SelectComponent
                label="Obtained from the Amway Corporation? "
                name="obtained-from-amway"
                onChange={(option: IOption) => handleSelectInputChange('obtainedFromAmway', option)}
                isDisabled={false}
                options={selectOptions['yes-no-options']}
                selectedOption={submissionDetailsData.obtainedFromAmway}
              />
              <SelectComponent
                label="Copyrighted Works of Amway in BSM? "
                name="copyrighted"
                onChange={(option: IOption) => handleSelectInputChange('copyrighted', option)}
                isDisabled={false}
                options={selectOptions['yes-no-options']}
                selectedOption={submissionDetailsData.copyrighted}
              />
              <div className="edit-submission-input-container levels-container">
                <div className="edit-submission-input-label">
                  Curriculum Segment / <br />
                  Audience:{' '}
                </div>
                <div className="levels-groups">
                  <div className="levels-group">
                    <div>1st Level</div>
                    <div>
                      <input
                        type="radio"
                        id="ibo"
                        name="fst-level"
                        checked={submissionDetailsData.fstLevel.value === 'ibo'}
                        onChange={() => handleRadioInputChange('fstLevel', 'ibo')}
                      />
                      <label htmlFor="ibo">IBO</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="prospect"
                        name="fst-level"
                        checked={submissionDetailsData.fstLevel.value === 'prospect'}
                        onChange={() => handleRadioInputChange('fstLevel', 'prospect')}
                      />
                      <label htmlFor="prospect">Prospect</label>
                    </div>
                  </div>
                  {submissionDetailsData.fstLevel.value === 'ibo' && (
                    <div className="levels-group">
                      <div>2nd Level</div>
                      <div>
                        <input
                          type="radio"
                          id="general_audience"
                          name="second-level"
                          checked={submissionDetailsData.secondLevel.value === 'general_audience'}
                          onChange={() => handleRadioInputChange('secondLevel', 'general_audience')}
                        />
                        <label htmlFor="general_audience">General Audience</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="platinums"
                          name="second-level"
                          checked={submissionDetailsData.secondLevel.value === 'platinums'}
                          onChange={() => handleRadioInputChange('secondLevel', 'platinums')}
                        />
                        <label htmlFor="platinums">Platinums and Above</label>
                      </div>
                    </div>
                  )}
                  <div className="levels-group">
                    <div>3rd Level</div>
                    <div>
                      <input
                        type="checkbox"
                        id="general-business"
                        name="third-level" // product, plan, general business, prospect
                        checked={submissionDetailsData.generalBusiness.value === 'true'}
                        onChange={event => handleCheckboxInputChange('generalBusiness', event.target.checked)}
                      />
                      <label htmlFor="general-business">General Business</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="plan"
                        name="third-level"
                        checked={submissionDetailsData.plan.value === 'true'}
                        onChange={event => handleCheckboxInputChange('plan', event.target.checked)}
                      />
                      <label htmlFor="plan">Plan</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="product"
                        name="third-level"
                        checked={submissionDetailsData.product.value === 'true'}
                        onChange={event => handleCheckboxInputChange('product', event.target.checked)}
                      />
                      <label htmlFor="product">Product</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="prospect-3"
                        name="third-level"
                        checked={submissionDetailsData.prospect.value === 'true'}
                        onChange={event => handleCheckboxInputChange('prospect', event.target.checked)}
                      />
                      <label htmlFor="prospect-3">Prospect</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-submission-input-container checkbox-container">
                <input
                  className="cc-checkbox"
                  type="checkbox"
                  id="authorized-signature"
                  onChange={event => handleCheckboxInputChange('authorizedSignature', event.target.checked)}
                  checked={submissionDetailsData.authorizedSignature.value === 'true'}
                />
                <label className="edit-submission-input-label" htmlFor="authorized-signature">
                  Authorized Signature
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="edit-submission-footer">
          <Button onClick={setSubmissionDetails} className="edit-submission-button-reset" disabled={isUpdating}>
            Reset changes
          </Button>
          <Button onClick={confirmChanges} disabled={isUpdating} className="edit-submission-button-confirm">
            {isUpdating ? (
              <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
            ) : (
              'Confirm changes'
            )}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
