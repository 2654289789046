import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { Assignee } from '../../interface/assignee';
import { ApprovedProvider } from '../../interface/approved-provider';
import { Affiliate } from '../../interface/affiliate';
import { WorkflowStatus } from '../../interface/workflow-status';
import { Submitter } from '../../interface/submitter';

export interface OptionsState {
  affiliates: Affiliate[];
  workflowStatuses: WorkflowStatus[];
  submitters: Submitter[];
  assignees: Assignee[];
  approvedProviders: ApprovedProvider[];
}

const initialState: OptionsState = {
  affiliates: [],
  workflowStatuses: [],
  submitters: [],
  assignees: [],
  approvedProviders: [],
};

const optionsSlice: Slice = createSlice({
  name: 'options',
  initialState: initialState,
  reducers: {
    setAffiliates: (state: OptionsState, action: PayloadAction<Affiliate[]>) => {
      state.affiliates = action.payload;
    },
    setWorkflowStatuses: (state: OptionsState, action: PayloadAction<WorkflowStatus[]>) => {
      state.workflowStatuses = action.payload;
    },
    setSubmitters: (state: OptionsState, action: PayloadAction<Submitter[]>) => {
      state.submitters = action.payload;
    },
    setAssignees: (state: OptionsState, action: PayloadAction<Assignee[]>) => {
      state.assignees = action.payload;
    },
    setApprovedProviders: (state: OptionsState, action: PayloadAction<ApprovedProvider[]>) => {
      state.approvedProviders = action.payload;
    },
  },
});

export const { setAffiliates, setWorkflowStatuses, setSubmitters, setAssignees, setApprovedProviders } =
  optionsSlice.actions;

export default optionsSlice.reducer;
