import { ReactNode, useEffect, useRef, useState } from 'react';

import './index.scss';
import { IOption } from '../multi-select-dropdown';

interface SelectComponentProps {
  options: Array<IOption>;
  selectedOption: IOption | IOption[];
  onChange: Function;
  name: string;
  isDisabled: boolean;
  label?: string | ReactNode;
  noBorders?: boolean;
  noPadding?: boolean;
  textColor?: string;
  multiple?: boolean;
}

export default function SelectComponent({
  options,
  selectedOption,
  onChange,
  name,
  isDisabled,
  label,
  noBorders,
  noPadding,
  textColor,
  multiple = false,
}: SelectComponentProps) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const myRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropDown);

    return () => {
      document.removeEventListener('click', handleClickOutsideDropDown);
    };
  }, []);

  function handleClickOutsideDropDown(event: any) {
    const dropDownArea = myRef.current! as HTMLElement;

    if (dropDownArea && !dropDownArea.contains(event.target)) {
      setShowDropdown(false);
    }
  }

  function toggleDropdown() {
    if (!isDisabled) setShowDropdown(!showDropdown);
  }

  function toggleOption(option: IOption, event: React.MouseEvent) {
    event.stopPropagation();

    if (multiple) {
      if (Array.isArray(selectedOption)) {
        const isSelected = selectedOption.some(selected => selected.value === option.value);
        const updatedSelection = isSelected
          ? selectedOption.filter(selected => selected.value !== option.value)
          : [...selectedOption, option];
        onChange(updatedSelection);
      }
    } else {
      onChange(option);
      setShowDropdown(false);
    }
  }

  function getSelectedOptionClass(option: IOption) {
    if (Array.isArray(selectedOption)) {
      const values: string[] = selectedOption.map(option => option.value);

      if (values.includes(option.value)) {
        return 'selected-option';
      }
      return '';
    }
    if ((selectedOption as IOption)?.value === option.value) {
      return 'selected-option';
    }
    return '';
  }

  return (
    <div className="custom-select-container" ref={myRef}>
      <div className="custom-select" onClick={() => toggleDropdown()}>
        <div className="select-label">{label}</div>

        <div className="select-input">
          <button
            className={`select-button ${noBorders ? 'no-borders' : ''} ${noPadding ? 'no-padding' : ''} ${
              isDisabled ? 'disabled' : ''
            }`}
            style={{ color: textColor }}
            role="combobox"
            aria-label="select button"
            aria-haspopup="listbox"
            aria-expanded="false"
            aria-controls="select-dropdown">
            <span className="selected-option">
              {multiple
                ? Array.isArray(selectedOption) && selectedOption.length > 0
                  ? selectedOption.map(option => option.label).join(', ')
                  : ''
                : (selectedOption as IOption)?.label}
            </span>
            <span className="arrow"></span>
          </button>

          <ul className="select-dropdown" hidden={!showDropdown} role="listbox">
            {options.map((option, index) => (
              <li
                id={`li_${option.value}_${name}`}
                className={`select-dropdown-option ${getSelectedOptionClass(option)}`}
                key={`${option.value}_${index}_${name}`}
                onClick={e => toggleOption(option, e)}
                value={option.value}>
                <input
                  type={multiple ? 'checkbox' : 'radio'}
                  id={`${option.value}_${name}`}
                  name={name}
                  checked={
                    multiple
                      ? Array.isArray(selectedOption) &&
                        selectedOption.some(selected => selected.value === option.value)
                      : (selectedOption as IOption)?.value === option.value
                  }
                  readOnly
                />
                <label htmlFor={`${option.value}_${name}`} onClick={e => e.preventDefault()}>
                  {option.label}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
