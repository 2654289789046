import { InputLabel } from '@mui/material';
import { Row } from 'react-bootstrap';
import AsyncAutocompleteComponent from '../../../components/ui/async-autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Item } from '../../../components/ui/dropdown-btn';
import { getFiltersFromStorage, saveFiltersToStorage } from '../../../helpers/filters-local-storage';
import { Affiliate } from '../../../interface/affiliate';
import { capitalizeWords } from '../../../utils/string-utils';
import { WorkflowStatus } from '../../../interface/workflow-status';
import { Assignee } from '../../../interface/assignee';
import { Submitter } from '../../../interface/submitter';
import { useAppSelector } from '../../../redux/hooks';
import { ApprovedProvider } from '../../../interface/approved-provider';
import { useFetchOptionsData } from '../../../hooks/options-data-hook';

interface Props {
  rowSearch: string;
  setRowSearch: (value: string) => void;
  selectedAffiliate: string[];
  setSelectedAffiliate: (value: string[]) => void;
  selectedStatus: string[];
  setSelectedStatus: (value: string[]) => void;
  selectedSubmitter: string[];
  setSelectedSubmitter: (value: string[]) => void;
  selectedUser: string[];
  setSelectedUser: (value: string[]) => void;
  selectedApprovedProvider: string[];
  setSelectedApprovedProvider: (value: string[]) => void;
  onOpenStateChange: (open: boolean) => void;
}

const DashboardFilters: React.FC<Props> = ({
  rowSearch,
  setRowSearch,
  selectedAffiliate,
  setSelectedAffiliate,
  selectedStatus,
  setSelectedStatus,
  selectedSubmitter,
  setSelectedSubmitter,
  selectedUser,
  setSelectedUser,
  selectedApprovedProvider,
  setSelectedApprovedProvider,
  onOpenStateChange,
}) => {
  const { affiliates, workflowStatuses, submitters, assignees, approvedProviders } = useAppSelector(
    state => state.options,
  );
  const { fetchOptionsData } = useFetchOptionsData();

  // load all the dropdown options just once
  useEffect(() => {
    fetchOptionsData({ affiliates, workflowStatuses, submitters, assignees, approvedProviders });
  }, []);

  const allAffiliatesOptions = useMemo<Item[]>(() => {
    if (!affiliates) {
      return [] as Item[];
    }

    const affiliatesOptions = affiliates.map((affiliate: Affiliate) => ({
      id: affiliate.affiliateCd.toString(),
      label: capitalizeWords(affiliate.affiliateName.toLowerCase()),
    })) as Item[];

    return affiliatesOptions;
  }, [affiliates]);

  const allStatusOptions = useMemo<Item[]>(() => {
    if (!workflowStatuses) {
      return [] as Item[];
    }

    const statusOptions = workflowStatuses.map((workflowStatus: WorkflowStatus) => ({
      id: workflowStatus.status,
      label: workflowStatus.status,
    })) as Item[];

    return statusOptions;
  }, [workflowStatuses]);

  const allSubmittersOptions = useMemo<Item[]>(() => {
    if (!submitters) {
      return [] as Item[];
    }

    const submitter = submitters.map((submitter: Submitter) => ({
      id: submitter.name,
      label: submitter.name,
    })) as Item[];

    return submitter;
  }, [submitters]);

  const allUsersOptions = useMemo<Item[]>(() => {
    if (!assignees) {
      return [] as Item[];
    }

    const assignedTo = assignees.map((assignee: Assignee) => ({
      id: assignee.name,
      label: assignee.name,
    })) as Item[];

    return assignedTo;
  }, [assignees]);

  const allApprovedProviders = useMemo<Item[]>(() => {
    if (!approvedProviders) {
      return [] as Item[];
    }

    const approvedProvidersOptions = approvedProviders.map((approvedProvider: ApprovedProvider) => ({
      id: approvedProvider.fullName,
      label: approvedProvider.fullName,
    })) as Item[];

    return approvedProvidersOptions;
  }, [approvedProviders]);

  const handleAffiliateChange = useCallback(
    (selectedItems: Item[]) => {
      const selectedAffiliateIds = selectedItems.map(item => item.id);
      setSelectedAffiliate(selectedAffiliateIds);
      saveFiltersToStorage({ ...getFiltersFromStorage(), affiliate: selectedAffiliateIds });
    },
    [setSelectedAffiliate],
  );

  const handleStatusChange = useCallback(
    (selectedItems: Item[]) => {
      const selectedStatusIds = selectedItems.map(item => item.id);
      setSelectedStatus(selectedStatusIds);
      saveFiltersToStorage({ ...getFiltersFromStorage(), status: selectedStatusIds });
    },
    [setSelectedStatus],
  );

  const handleSubmitterChange = useCallback(
    (selectedItems: Item[]) => {
      const selectedSubmitterIds = selectedItems.map(item => item.id);
      setSelectedSubmitter(selectedSubmitterIds);
      saveFiltersToStorage({ ...getFiltersFromStorage(), selectedSubmitter: selectedSubmitterIds });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedSubmitter],
  );

  const handleSelectedUserChange = useCallback(
    (selectedItems: Item[]) => {
      const selectedUserIds = selectedItems.map(item => item.id);
      setSelectedUser(selectedUserIds);
      saveFiltersToStorage({ ...getFiltersFromStorage(), selectedUser: selectedUserIds });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedUser],
  );

  const handleApprovedProviderChange = useCallback(
    (selectedItems: Item[]) => {
      const selectedApprovedProviderIds = selectedItems.map(item => item.id);
      setSelectedApprovedProvider(selectedApprovedProviderIds);
      saveFiltersToStorage({ ...getFiltersFromStorage(), approvedProvider: selectedApprovedProviderIds });
    },
    [setSelectedApprovedProvider],
  );

  const selectedAffiliateItems = useMemo(() => {
    return selectedAffiliate.length > 0
      ? allAffiliatesOptions.filter(option => selectedAffiliate.includes(option.id))
      : [];
  }, [selectedAffiliate, allAffiliatesOptions]);

  const selectedStatusItems = useMemo(() => {
    return selectedStatus.length > 0 ? allStatusOptions.filter(option => selectedStatus.includes(option.id)) : [];
  }, [selectedStatus, allStatusOptions]);

  const selectedUserItems = useMemo(() => {
    return selectedUser.length > 0 ? allUsersOptions.filter(it => selectedUser.includes(it.id)) : [];
  }, [selectedUser, allUsersOptions]);

  const selectedSubmitterItems = useMemo(() => {
    return selectedSubmitter.length > 0 ? allSubmittersOptions.filter(it => selectedSubmitter.includes(it.id)) : [];
  }, [selectedSubmitter, allSubmittersOptions]);

  const selectedApprovedProviderItems = useMemo(() => {
    return selectedApprovedProvider.length > 0
      ? allApprovedProviders.filter(option => selectedApprovedProvider.includes(option.id))
      : [];
  }, [selectedApprovedProvider, allApprovedProviders]);

  const handleAffiliateSearch = useCallback(
    (affiliateName: string) => {
      return Promise.resolve(
        affiliateName !== ''
          ? allAffiliatesOptions.filter(affiliateOption =>
              affiliateOption.label.toLowerCase().startsWith(affiliateName.toLowerCase()),
            )
          : allAffiliatesOptions,
      );
    },
    [allAffiliatesOptions],
  );

  const handleStatusSearch = useCallback(
    (status: string) => {
      return Promise.resolve(
        status !== ''
          ? allStatusOptions.filter(statusOption => statusOption.label.toLowerCase().startsWith(status.toLowerCase()))
          : allStatusOptions,
      );
    },
    [allStatusOptions],
  );

  const handleUserSearch = useCallback(
    (usernameOrEmail: string) => {
      return Promise.resolve(
        usernameOrEmail !== ''
          ? allUsersOptions.filter(
              userOption =>
                userOption.label.toLowerCase().startsWith(usernameOrEmail.toLowerCase()) ||
                userOption.thirdLabel?.toLowerCase()?.startsWith(usernameOrEmail.toLowerCase()),
            )
          : allUsersOptions,
      );
    },
    [allUsersOptions],
  );

  const handleSubmitterSearch = useCallback(
    (submitter: string) => {
      return Promise.resolve(
        submitter !== ''
          ? allSubmittersOptions.filter(submitterOption =>
              submitterOption.label.toLowerCase().startsWith(submitter.toLowerCase()),
            )
          : allSubmittersOptions,
      );
    },
    [allSubmittersOptions],
  );

  const handleApprovedProviderSearch = useCallback(
    (providerName: string) => {
      return Promise.resolve(
        providerName !== ''
          ? allApprovedProviders.filter(providerOption =>
              providerOption.label.toLowerCase().startsWith(providerName.toLowerCase()),
            )
          : allApprovedProviders,
      );
    },
    [allApprovedProviders],
  );

  return (
    <div className="flex options">
      <Row className="id-search">
        <InputLabel className="input-label-search">
          <b>Search:</b>
        </InputLabel>
        <input
          value={rowSearch}
          placeholder="Enter a full or partial Apply Code or any text"
          onChange={event => {
            setRowSearch(event.target.value);
            saveFiltersToStorage({ ...getFiltersFromStorage(), search: event.target.value });
          }}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Affiliate:"
          placeholder="Search affiliate"
          styleType="filter"
          items={allAffiliatesOptions}
          search={handleAffiliateSearch}
          selectedValue={selectedAffiliateItems}
          selectValue={handleAffiliateChange}
          onOpenStateChange={onOpenStateChange}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Status:"
          placeholder="Search status"
          styleType="filter"
          items={allStatusOptions}
          search={handleStatusSearch}
          selectedValue={selectedStatusItems}
          selectValue={handleStatusChange}
          onOpenStateChange={onOpenStateChange}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Approved Provider:"
          placeholder="Search approved provider"
          styleType="filter"
          items={allApprovedProviders}
          search={handleApprovedProviderSearch}
          selectedValue={selectedApprovedProviderItems}
          selectValue={handleApprovedProviderChange}
          onOpenStateChange={onOpenStateChange}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Submitted By:"
          placeholder="Search submitter"
          styleType="filter"
          items={allSubmittersOptions}
          search={handleSubmitterSearch}
          selectedValue={selectedSubmitterItems}
          selectValue={handleSubmitterChange}
          onOpenStateChange={onOpenStateChange}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Assigned To:"
          placeholder="Search User ID or email"
          styleType="filter"
          items={allUsersOptions}
          search={handleUserSearch}
          selectedValue={selectedUserItems}
          selectValue={handleSelectedUserChange}
          onOpenStateChange={onOpenStateChange}
        />
      </Row>
    </div>
  );
};

export default DashboardFilters;
