import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { EditSubmissionDTO } from '../../interface/edit-submission-dto';

class EditSubmissionService {
  public updateSubmissionDetails(
    submissionId: number,
    data: EditSubmissionDTO,
  ): WithAbortFn<Promise<EditSubmissionDTO>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post(`/edit-submission/${submissionId}`, data).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const editSubmissionService = new EditSubmissionService();
export default editSubmissionService;
